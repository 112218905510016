@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 300;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Light.ttf") format("truetype");
}

@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 400;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 500;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 600;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Semibold.ttf")
    format("truetype");
}

@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 700;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Bold.ttf") format("truetype");
}

@font-face {
  font-family: NoiGroteskTrial;
  font-weight: 800;
  src: url("fonts/NoiGroteskTrial/NoiGroteskTrial-Black.ttf") format("truetype");
}
