/*@import url('https://fonts.googleapis.com/css?family=Inter:wght@500;900');
*/

@import url("styles/fonts/Inter/web/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

html,
body {
  height: 100%;
}
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
}

.rotate {
  animation: rotation 5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
